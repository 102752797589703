<template>
  <svg id="logo" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 776 188.1">
    <path d="M480.8 185.5V2.5H565v27.4h-52.8V77h40.6v27.4h-40.6v53.7h56.3v27.4zM638.7 47.6c-1.3-15.7-9.6-20.3-16.7-20.3-10.1 0-15.7 6.6-15.7 18 0 31.2 63.9 46.1 63.9 95.8 0 30.2-20.3 46.9-49.7 46.9-29.1 0-45.9-23.3-47.1-50.7l29.9-4.3c1.3 18.5 8.6 27.6 18.8 27.6 10.9 0 18.3-5.8 18.3-16.7 0-36.2-63.9-45.9-63.9-98.3C576.4 16.5 594.1 0 625 0c25.6 0 40.8 18.5 43.6 44.4l-29.9 3.2zm105.9 0c-1.3-15.7-9.6-20.3-16.7-20.3-10.1 0-15.7 6.6-15.7 18 0 31.2 63.9 46.1 63.9 95.8 0 30.2-20.3 46.9-49.7 46.9-29.1 0-45.9-23.3-47.1-50.7l29.9-4.3c1.3 18.5 8.6 27.6 18.8 27.6 10.9 0 18.2-5.8 18.2-16.7 0-36.2-63.9-45.9-63.9-98.3C682.2 16.5 700 0 730.9 0c25.6 0 40.8 18.5 43.6 44.4l-29.9 3.2zm-679.2 0c-1.3-15.7-9.6-20.3-16.7-20.3-10.1 0-15.7 6.6-15.7 18 0 31.2 63.9 46.1 63.9 95.8 0 30.2-20.3 46.9-49.7 46.9-29.2.1-45.9-23.3-47.2-50.6l29.9-4.3c1.3 18.5 8.6 27.6 18.8 27.6 10.9 0 18.3-5.8 18.3-16.7-.1-36.3-64-45.9-64-98.4C3 16.5 20.8 0 51.7 0c25.6 0 40.8 18.5 43.6 44.4l-29.9 3.2zm76.9 92.8c0 13.7 4.1 20.3 16.7 20.3 12.7 0 16.7-6.6 16.7-20.3V47.6c0-13.7-4.1-20.3-16.7-20.3-12.7 0-16.7 6.6-16.7 20.3v92.8zm-31.5-93.5C110.8 21 126.1 0 159 0c32.9 0 48.2 21 48.2 46.9v94.3c0 25.9-15.2 46.9-48.2 46.9-32.9 0-48.2-21-48.2-46.9V46.9zm288.4 37.7h11.2c17 0 23.3-6.3 23.3-28.1s-6.3-28.1-23.3-28.1h-11.2v56.2zm0 100.9h-31.4V2.5h44.1c35.2 0 53.2 15 53.2 54 0 29.4-11.4 41.3-22.1 46.1l26.6 82.9h-31.9l-22.3-75.8c-4.1.5-10.6.8-16.2.8v75zM286.7 28.4h11.2c4.2 0 7.8.4 10.8 1.3 8.9 2.8 12.5 10.4 12.5 26.8 0 21.8-6.3 28.1-23.3 28.1h-11.2V28.4zm65.8 27.2c-.2-20.6-5.5-34.3-15.7-42.7-.2-.1-.3-.3-.5-.4-.9-.7-1.8-1.3-2.7-1.9-.3-.2-.7-.5-1-.7-.8-.5-1.7-1-2.6-1.4-.5-.2-1-.5-1.5-.7-.9-.4-1.7-.8-2.6-1.1-.6-.2-1.3-.5-1.9-.7-.9-.3-1.7-.6-2.6-.8-.8-.2-1.5-.4-2.3-.6-.9-.2-1.7-.4-2.6-.6-.9-.2-1.9-.3-2.8-.5-.8-.1-1.6-.3-2.5-.4l-3.6-.3c-.7 0-1.4-.1-2.1-.2-2-.1-4-.2-6.1-.2h-44.1v183h31.4v-75h12.7c31.2 0 48.8-11.8 52.4-41.7.5-3.7.8-7.8.8-12.3 0-.2-.1-.5-.1-.8"/>
  </svg>
</template>
<script>
  export default {
    name : 'Logo'

  }
</script>
<style scoped>

</style>